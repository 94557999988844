<template>
    <div v-if="loading == false">
        <!--            :target_page="targetPage"-->
        <ChirpList
            :data_source="rows"
            :export_api="export_api"
            :totalCount="totalCount"
            @applyCriteria="modifyCriteria"
            :column_meta="columnMeta"
            :loadingColumns="loadingTable"
            :storeKey="storeKey"
            :useOverride="useOverride"
            ignore-date-conversion
            @cancelOverride="handleCancelOverride"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'ledger/list';

    import ChirpList from '@/components/general/list/ChirpList';
    import dayjs from '@/util/dayjs';

    export default {
        name: 'Ledger',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                totalCount: 0,
                useOverride: false,
                // targetPage: 'LedgerDetail',
                loading: true,
                loadingTable: false,
                criteriaProps: {},
                export_api: '/ledger/list',
                columnMeta: [
                    { field: 'client_name', headerText: 'Client' },
                    { field: 'description', headerText: 'Event', width: '35%' },
                    { field: 'service_code', headerText: 'CPT Code' },
                    { field: 'invoice_id', headerText: 'Invoice #', align: 'Center' },
                    { field: 'ledger_date', headerText: 'Date', align: 'Right' },
                    { field: 'amount', headerText: 'Amount', align: 'Right' }
                ]
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'ledger';
            }
        },
        methods: {
            async load_data() {
                const body = {
                    criteria: this.criteria
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows.map((row) => {
                    return {
                        client_name: row.client_name,
                        description: row.description,
                        service_code: row.service_code,
                        invoice_id: row.invoice_id,
                        ledger_date: dayjs(row.ledger_date).format('MM/DD/YYYY'),
                        amount: row.amount
                    };
                });
                this.totalCount = res.data.total_count;
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }

                if (!this.criteria.sort || (this.criteria.sort && Object.keys(this.criteria.sort).length === 0)) {
                    this.$store.commit('filters/applyDefaultDateSort', {
                        stateKey: this.storeKey,
                        customDateKey: 'ledger_date',
                    });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria,
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        }
    };
</script>
